import 'element-closest-polyfill';
import smoothscroll from 'smoothscroll-polyfill';

// kick off the smoothscroll polyfill!
smoothscroll.polyfill();

// polyfill for crypto.randomUUID
if (!('randomUUID' in crypto)) {
  // https://stackoverflow.com/a/2117523/2800218
  crypto.randomUUID = function randomUUID() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
    );
  };
}
